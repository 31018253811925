var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"bt card-style",attrs:{"id":"entity-users-list","element-loading-text":_vm.loadingText}},[(_vm.activeEntityView == 'TABLE')?[_c('div',{staticClass:"vue-data-table-default non-scale"},[(_vm.entityFiltersData && _vm.entityFiltersData.tableFieldGroupBy)?[(!_vm.refresh)?_c('el-table',{staticClass:"sidemenu",staticStyle:{"overflow-wrap":"normal"},attrs:{"data":_vm.groupedData,"total":_vm.total,"resizable":true,"border":"","current-page":_vm.currentPage,"page-size":_vm.pageSize,"pagination-props":_vm.paginationProps,"page-sizes":_vm.paginationProps,"size":"mini"},on:{"header-dragend":_vm.headerDragged,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"query-change":_vm.loadData,"sort-change":_vm.sortChange}},[(
              (_vm.activeEntityView == 'TABLE' &&
                _vm.checkPerimission('BULK UPDATE')) ||
              _vm.checkPerimission('BULK DELETE') ||
              _vm.checkPerimission('BULK DOWNLOAD')
            )?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"200","sortable":"custom","prop":"_id","label":_vm.getMainLabel},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row['entity_label'])?_c('div',[_vm._v(" "+_vm._s(scope.row["entity_label"])+" ")]):_c('div',[_vm._v(" "+_vm._s(scope.row["_id"])+" ")])]}}],null,false,2655631302)}),_vm._l((_vm.entityFiltersData.tableFieldGroupByFields.filter(
              (e) => !e.sub_group
            )),function(step,index){return _c('el-table-column',{key:step.key + index,attrs:{"min-width":"250","label":step.name,"sortable":"custom","prop":step.key},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.openCountDialog(scope.row, null)}}},[_vm._v(_vm._s(_vm.getCurrencyFormat(scope.row[step.key], step.key))+" ")])],1)]}}],null,true)})}),_vm._l((_vm.custom_columns),function(step,index){return _c('el-table-column',{key:index + '_' + step.value,attrs:{"label":step.label,"min-width":"250","prop":step.value},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.openCountDialog(scope.row, step.value)}}},[_vm._v(_vm._s(_vm.getSubField(scope.row, step.value)))])],1)]}}],null,true)})})],2):_vm._e()]:[(!_vm.refresh)?_c('el-table',{staticClass:"sidemenu",staticStyle:{"width":"100%"},attrs:{"data":_vm.formattedEntityData,"total":_vm.total,"resizable":true,"current-page":_vm.currentPage,"page-size":_vm.pageSize,"pagination-props":_vm.paginationProps,"page-sizes":_vm.paginationProps,"default-sort":_vm.getSort(),"border":"","size":"mini"},on:{"header-dragend":_vm.headerDragged,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"query-change":_vm.loadData,"sort-change":_vm.sortChange,"selection-change":_vm.handleSelectionChange}},[(
              _vm.checkPerimission('BULK UPDATE') ||
              _vm.checkPerimission('BULK DELETE') ||
              _vm.checkPerimission('BULK DOWNLOAD')
            )?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_vm._l((_vm.getFilterColumList),function(step){return _c('el-table-column',{key:step.template_id + '-' + step.id + '-' + step.label,staticClass:"tableView",attrs:{"label":step.label,"prop":_vm.getPropValue(step),"width":step.width ? step.width : 250,"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:(_vm.getFieldStyle(step, _vm.entityDataObject[scope.row._id]))},[(step && _vm.isSystemColumn(step))?_c('div',[_c('div',[(scope.row.updated_at && step.id == 'updated_at')?_c('span',{staticClass:"black-coral sm-100"},[_vm._v(_vm._s(_vm._f("globalDateTimeFormat")(scope.row.updated_at)))]):_vm._e(),(scope.row.created_at && step.id == 'created_at')?_c('span',{staticClass:"black-coral sm-100"},[_vm._v(_vm._s(_vm._f("globalDateTimeFormat")(scope.row.created_at)))]):_vm._e(),(
                        scope.row.created_by &&
                        step.id == 'created_by' &&
                        _vm.entityUserInfoData?.[scope.row.created_by]
                      )?_c('span',{staticClass:"black-coral sm-100"},[_vm._v(" "+_vm._s(_vm.entityUserInfoData?.[scope.row.created_by] .first_name + " " + _vm.entityUserInfoData?.[scope.row.created_by].last_name)+" ")]):_vm._e(),(
                        scope.row.updated_by &&
                        step.id == 'updated_by' &&
                        _vm.entityUserInfoData?.[scope.row.updated_by]
                      )?_c('span',{staticClass:"black-coral sm-100"},[_vm._v(" "+_vm._s(_vm.entityUserInfoData?.[scope.row.updated_by] .first_name + " " + _vm.entityUserInfoData?.[scope.row.updated_by].last_name)+" ")]):_vm._e(),(scope.row.status && step.id == 'status')?_c('div',{staticClass:"black-coral sm-100"},[_c('span',{class:scope.row.status.toLowerCase() + ' circle'},[_vm._v(" "+_vm._s(scope.row.status == "ACTIVE" ? "Active" : scope.row.status == "DRAFT" ? "Draft" : "Archived")+" ")])]):_vm._e(),(
                        _vm.currentEntity.entity_type == 'INDIVIDUAL' &&
                        step.id == 'profile_picture_with_name'
                      )?_c('div',[_c('div',{staticClass:"icon-container"},[_c('div',{staticClass:"icon-block initial"},[(
                              scope.row &&
                              scope.row.contact_id &&
                              _vm.getProfilePicture(
                                _vm.entityDataObject[scope.row._id]
                              )
                            )?_c('div',[_c('img',{staticStyle:{"border-radius":"50%","margin-top":"2px"},attrs:{"src":_vm.getProfilePicture(
                                  _vm.entityDataObject[scope.row._id]
                                ),"alt":"IMG","width":"35","height":"35"}})]):_c('div',{staticClass:"icon"},[_c('div',{staticClass:"initial-text"},[(
                                  _vm.entityDataObject[scope.row._id] &&
                                  _vm.getStandardTemplateInfo(
                                    _vm.entityDataObject[scope.row._id]
                                  )
                                )?_c('span',[_vm._v(" "+_vm._s(_vm.getInitials( _vm.getStandardTemplateInfo( _vm.entityDataObject[scope.row._id] ) ))+" ")]):_vm._e()])])]),_c('div',{staticClass:"pl-2"},[(_vm.entityDataObject[scope.row._id])?_c('span',[_vm._v(" "+_vm._s(_vm.getStandardTemplateInfo( _vm.entityDataObject[scope.row._id] ).replace(",", " "))+" ")]):_vm._e()])])]):_vm._e()])]):(step && step.type == 'ENTITY')?_c('div',{staticClass:"d-flex"},[(
                      scope.row[
                        step.template_id +
                          '#' +
                          (step.key ? step.key : step.id) +
                          '/checkCurrency'
                      ]
                    )?_c('div',[_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){_vm.openDetailedCount(
                          step.template_id +
                            '#' +
                            (step.key ? step.key : step.id),
                          _vm.entityDataObject[scope.row._id]
                        )}}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(Number(scope.row[step.template_id + "#" + step.id]), scope.row[ step.template_id + "#" + step.id + "/checkCurrency" ].currency ))+" ")])],1):(
                      scope.row[
                        step.template_id +
                          '#' +
                          step.id +
                          '/checkIsEntityCount'
                      ]
                    )?_c('div',[_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.openDetailedCount(
                          step.template_id + '#' + step.key,
                          _vm.entityDataObject[scope.row._id]
                        )}}},[_vm._v(" "+_vm._s(scope.row[step.template_id + "#" + step.id])+" ")])],1):(
                      step &&
                      step.type == 'ENTITY' &&
                      scope.row[step.template_id + '#' + step.id] &&
                      typeof scope.row[step.template_id + '#' + step.id] ==
                        'string'
                    )?_c('div',[(
                        scope.$index == _vm.quickUpdateIndex &&
                        _vm.checkPermissions(step)
                      )?_c('div',[_c('EntityExecute',{attrs:{"data":_vm.getFieldForQuickUpdate(step),"form":_vm.quickUpdateRowData[step.template_id],"is-view":false,"hasLabel":false,"entityDataExists":true,"fieldsData":[],"isApplicationUserSide":_vm.isApplicationUserSide}})],1):_c('div',[_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.checkApplicationUserPermission(
                            _vm.entityDataObject[scope.row._id],
                            step
                          )}}},[_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(scope.row[step.template_id + "#" + step.id])+" ")])])],1)]):_c('div',[(
                        scope.row[step.template_id + '#' + step.id] &&
                        scope.row[step.template_id + '#' + step.id].length > 0
                      )?_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(scope.row[step.template_id + "#" + step.id])+" ")]):_vm._e()])]):(step && step.type == 'ACTION_BUTTON')?_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.onActionButtonOpen(
                        step,
                        _vm.entityDataObject[scope.row._id],
                        scope.$index
                      )}}},[_vm._v(" "+_vm._s(step.label)+" ")])],1):(step && step.type == 'FILE')?_c('div',[(
                      scope.row[
                        step.template_id +
                          '#' +
                          (step.key ? step.key : step.id)
                      ] &&
                      scope.row[
                        step.template_id +
                          '#' +
                          (step.key ? step.key : step.id)
                      ].files &&
                      scope.row[
                        step.template_id +
                          '#' +
                          (step.key ? step.key : step.id)
                      ].files.length > 1
                    )?_c('div',{staticClass:"d-flex"},[_c('div',[_c('el-button',{staticStyle:{"margin-top":"-10px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadFile(
                            scope.row[step.template_id + '#' + step.key]
                              .files[0]
                          )}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(scope.row[step.template_id + "#" + step.key] .files[0].name,15, "..."))+" "),_c('i',{staticClass:"el-icon-download"})]),(
                          scope.row[step.template_id + '#' + step.key].files
                            .length > 1
                        )?_c('el-button',{staticStyle:{"margin-top":"-10px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadDocumentsPopup(
                            scope.row[step.template_id + '#' + step.key].files
                          )}}},[_vm._v(" + "+_vm._s(scope.row[step.template_id + "#" + step.key].files .length - 1)+" ")]):_vm._e()],1),(
                        scope.row[step.template_id + '#' + step.key].length >
                        1
                      )?_c('el-tag',{staticClass:"ml-1",staticStyle:{"cursor":"pointer","width":"40px"},attrs:{"value":scope.row[step.template_id + '#' + step.key].length},on:{"click":function($event){return _vm.downloadDocumentsPopup(
                          scope.row[step.template_id + '#' + step.key]
                        )}}},[_vm._v(" +"+_vm._s(scope.row[step.template_id + "#" + step.key].length - 1)+" ")]):_vm._e()],1):(
                      scope.row[step.template_id + '#' + step.id] &&
                      scope.row[step.template_id + '#' + step.id].name &&
                      scope.row[step.template_id + '#' + step.id].path
                    )?_c('div',[_c('el-button',{staticClass:"mb-1",staticStyle:{"margin-top":"-14px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadFile(
                          scope.row[step.template_id + '#' + step.id]
                        )}}},[_c('p',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(scope.row[step.template_id + "#" + step.id].name,20, "..."))+" "),_c('i',{staticClass:"el-icon-download m-rl-2"})])]),(
                        scope.row[step.template_id + '#' + step.id].files
                          .length == 1
                      )?_c('el-tooltip',{attrs:{"content":"Preview","placement":"bottom"}},[_c('el-button',{staticStyle:{"margin-top":"-10px","margin-left":"5px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onPreview(
                            scope.row[step.template_id + '#' + step.id]
                              .files[0]
                          )}}},[_c('i',{staticClass:"el-icon-view"})])],1):_vm._e()],1):_vm._e()]):(
                    step && (step.type === 'IMAGE' || step.type === 'GIF')
                  )?_c('div',{staticClass:"d-flex"},[(
                      Array.isArray(
                        scope.row[
                          step.template_id +
                            '#' +
                            (step.key ? step.key : step.id)
                        ]
                      ) &&
                      scope.row[
                        step.template_id +
                          '#' +
                          (step.key ? step.key : step.id)
                      ].length > 1
                    )?_c('div',[_c('div',{staticClass:"thumbnail",on:{"click":function($event){return _vm.openGallery(
                          scope.row[step.template_id + '#' + step.id]
                        )}}},[_c('img',{attrs:{"src":scope.row[step.template_id + '#' + step.id][0],"alt":"Thumbnail","width":"80","height":"60"}})])]):(scope.row[step.template_id + '#' + step.id])?_c('img',{attrs:{"src":scope.row[step.template_id + '#' + step.id],"alt":"IMG","width":"80","height":"60"}}):_vm._e()]):(step && step.type === 'QR')?_c('div',{staticClass:"d-flex"},[(
                      _vm.getFieldValue(_vm.entityDataObject[scope.row._id], step)
                    )?_c('img',{attrs:{"src":_vm.getFieldValue(_vm.entityDataObject[scope.row._id], step),"alt":"IMG","width":"80","height":"80"}}):_vm._e()]):(step && step.type === 'QUESTION')?_c('div',{staticClass:"d-flex",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(scope.row[ step.template_id + "#" + (step.key ? step.key : step.id) ])+" ")]):(step && step.type == 'DATA_TABLE')?_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.dataTableOpen(_vm.entityDataObject[scope.row._id], step)}}},[_vm._v(" "+_vm._s(scope.row[ step.template_id + "#" + (step.key ? step.key : step.id) ]?.length || 0)+" ")])]):(step && step.type == 'LOCATION')?_c('div',[_c('el-link',{staticStyle:{"font-size":"13px"},attrs:{"underline":false,"type":"primary"},on:{"click":function($event){_vm.showMap(
                        scope.row[
                          step.template_id +
                            '#' +
                            (step.key ? step.key : step.id)
                        ]
                      )}}},[_vm._v(" "+_vm._s(scope.row[ step.template_id + "#" + (step.key ? step.key : step.id) ])+" ")])],1):(step && step.type == 'MULTI_LINE_TEXT')?_c('div',[(
                      scope.row[
                        step.template_id +
                          '#' +
                          (step.key ? step.key : step.id)
                      ] &&
                      (!step.currency_code || !step.currency_code.data_type)
                    )?_c('span',[(
                        scope.row[
                          step.template_id +
                            '#' +
                            (step.key ? step.key : step.id)
                        ].length > 100
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.truncateText( scope.row[ step.template_id + "#" + (step.key ? step.key : step.id) ] ))+" "),_c('a',{staticStyle:{"color":"#409eff"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.openMultiLineData(
                            scope.row[
                              step.template_id +
                                '#' +
                                (step.key ? step.key : step.id)
                            ],
                            step.label,
                            step
                          )}}},[_vm._v("Read more")])]):_c('span',{domProps:{"innerHTML":_vm._s(
                        scope.row[
                          step.template_id +
                            '#' +
                            (step.key ? step.key : step.id)
                        ]
                      )}})]):(
                      scope.row[
                        step.template_id +
                          '#' +
                          (step.key ? step.key : step.id)
                      ]
                    )?_c('span',[(
                        scope.row[
                          step.template_id +
                            '#' +
                            (step.key ? step.key : step.id)
                        ].length > 100
                      )?_c('span',[_vm._v(" "+_vm._s(_vm._f("truncateHTML")(scope.row[ step.template_id + "#" + (step.key ? step.key : step.id) ],100))+" "),_c('a',{staticStyle:{"color":"#409eff"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.openMultiLineData(
                            scope.row[
                              step.template_id +
                                '#' +
                                (step.key ? step.key : step.id)
                            ],
                            step.label,
                            step
                          )}}},[_vm._v("Read more")])]):_c('span',[_vm._v(" "+_vm._s(_vm._f("truncateHTML")(scope.row[ step.template_id + "#" + (step.key ? step.key : step.id) ],100))+" ")]),(
                        scope.$index == _vm.quickUpdateIndex &&
                        _vm.checkPermissions(step)
                      )?_c('div',[_c('el-input',{style:(_vm.getStyleWidth(step)),attrs:{"disabled":_vm.quickUpdateFieldDisabled(
                            step.template_id,
                            step.id,
                            _vm.currentEntity
                          )},model:{value:(
                          _vm.quickUpdateRowData[step.template_id][step.id]
                        ),callback:function ($$v) {_vm.$set(_vm.quickUpdateRowData[step.template_id], step.id, $$v)},expression:"\n                          quickUpdateRowData[step.template_id][step.id]\n                        "}})],1):_vm._e()]):_vm._e()]):(
                    scope.row[
                      step.template_id + '#' + (step.key ? step.key : step.id)
                    ] &&
                    scope.row[
                      step.template_id +
                        '#' +
                        (step.key ? step.key : step.id) +
                        '_code'
                    ] &&
                    scope.row[
                      step.template_id +
                        '#' +
                        (step.key ? step.key : step.id) +
                        '_code'
                    ].formatInternational &&
                    scope.row[
                      step.template_id +
                        '#' +
                        (step.key ? step.key : step.id) +
                        '_code'
                    ].countryCallingCode
                  )?_c('div',[_c('p',{staticStyle:{"font-size":"13px"}},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.$showPhoneNumberDialog(
                          `${
                            scope.row[
                              step.template_id +
                                '#' +
                                (step.key ? step.key : step.id) +
                                '_code'
                            ].formattedNumber
                          }`
                        )}}},[_vm._v(_vm._s(scope.row[ step.template_id + "#" + (step.key ? step.key : step.id) + "_code" ].formatInternational))])])]):(
                    step &&
                    step.currency_code &&
                    step.currency_code.type == 'HYPER_LINK'
                  )?_c('div',[_c('el-tooltip',{attrs:{"placement":"top","content":scope.row[step.template_id + '#' + step.id]}},[_c('el-link',{staticStyle:{"color":"rgb(69 188 237)"},on:{"click":function($event){return _vm.checkNavigationAction(
                          _vm.entityDataObject[scope.row._id],
                          step
                        )}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(scope.row[step.template_id + "#" + step.id],25, "..."))+" ")])],1)],1):_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(scope.row[ step.template_id + "#" + (step.key ? step.key : step.id) ])+" ")])])]}}],null,true)})}),_c('el-table-column',{staticClass:"action",attrs:{"prop":"action","label":"Actions","min-width":_vm.getIsMobile ? '120' : '175',"fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  scope.row.status == 'ACTIVE' || scope.row.status == 'DRAFT'
                )?_c('ul',{staticClass:"action-buttons"},[(
                    _vm.checkPerimission('VIEW') &&
                    _vm.IframePermissions('VIEW', 'ViewEntityData') &&
                    _vm.checkRolePermission('viewEntitiesData') &&
                    scope.$index !== _vm.quickUpdateIndex &&
                    scope.row.status != 'DRAFT'
                  )?_c('li',[(
                      (_vm.customization &&
                        _vm.customizationButtons &&
                        _vm.customizationButtons.length) ||
                      (_vm.customization != null &&
                        _vm.customization.actions &&
                        _vm.customization.actions.length &&
                        _vm.getIframeUrlInfo == null)
                    )?[_vm._l((_vm.primaryActions),function(action,index){return _c('button',{key:index,staticClass:"icon-circle btn btn-light btn-sm m-lr-1",style:({
                        backgroundColor: action?.color ? action.color : '',
                      }),on:{"click":function($event){return _vm.onAction(action, _vm.entityDataObject[scope.row._id])}}},[_c('el-tooltip',{staticStyle:{"display":"flex","align-items":"center","gap":"8px"},attrs:{"placement":"top","content":action.label}},[(!action.icon)?[_c('i',{class:_vm.fetchActionIcon(action),staticStyle:{"font-size":"16px"}})]:[_c('icons',{staticStyle:{"font-size":"14px"},attrs:{"iconName":action.icon}})]],2)],1)}),_vm._l((_vm.getButtonsList(_vm.customizationButtons)),function(button){return _c('button',{key:button,staticClass:"btn btn-light btn-sm m-lr-1",attrs:{"type":"button"}},[(
                          button === 'Edit' &&
                          _vm.checkRolePermission('editEntitiesData')
                        )?_c('span',{staticClass:"text-muted",attrs:{"title":"Edit"},on:{"click":function($event){return _vm.editWorkUserData(_vm.entityDataObject[scope.row._id])}}},[_c('i',{staticClass:"el-icon-edit"})]):(
                          button === 'View' &&
                          _vm.checkRolePermission('viewEntitiesData')
                        )?_c('span',{staticClass:"text-muted",attrs:{"title":"View"},on:{"click":function($event){return _vm.viewWorkUserData(_vm.entityDataObject[scope.row._id])}}},[_c('i',{staticClass:"fa fa-eye"})]):(
                          button === 'Delete' &&
                          _vm.checkRolePermission('deleteEntitiesData')
                        )?_c('span',{staticClass:"text-muted",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.onEntityDataDelete(_vm.entityDataObject[scope.row._id])}}},[_c('i',{staticClass:"el-icon-delete"})]):(button === 'Send Notification')?_c('span',{staticClass:"text-muted",attrs:{"title":"Send Notification"},on:{"click":function($event){return _vm.openSendNotification(
                            _vm.entityDataObject[scope.row._id]
                          )}}},[_c('i',{staticClass:"fa-solid fa-share"})]):_vm._e()])})]:[_c('button',{staticClass:"btn btn-light btn-sm m-lr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.viewWorkUserData(_vm.entityDataObject[scope.row._id])}}},[_c('span',{staticClass:"text-muted",attrs:{"title":"View"}},[_c('i',{staticClass:"fa fa-eye"})])])]],2):_vm._e(),(scope.$index == _vm.quickUpdateIndex)?_c('li',[_c('el-button',{staticClass:"px-2 ml-2",attrs:{"plain":"","type":"danger","size":"mini","title":"Cancel"},on:{"click":function($event){_vm.quickUpdateIndex = -1}}},[_c('i',{staticClass:"el-icon-close"})])],1):_vm._e(),(scope.$index == _vm.quickUpdateIndex)?_c('li',[_c('el-button',{staticClass:"px-2 ml-2",attrs:{"plain":"","type":"success","size":"mini","title":"Save"},on:{"click":function($event){return _vm.updateRow(_vm.entityDataObject[scope.row._id])}}},[_c('i',{staticClass:"el-icon-check"})])],1):_vm._e(),(
                    scope.$index !== _vm.quickUpdateIndex && _vm.isDropdownVisible()
                  )?_c('li',[_c('el-dropdown',[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-more",staticStyle:{"transform":"rotate(90deg)","position":"relative","right":"10px"}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                          _vm.checkPerimission('DUPLICATE') &&
                          _vm.currentEntity &&
                          _vm.currentEntity.entity_type != 'INDIVIDUAL'
                        )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.duplicateEntityData(_vm.entityDataObject[scope.row._id])}}},[(
                            _vm.checkButtonVisible('Duplicate') &&
                            scope.row.status != 'DRAFT'
                          )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-document-copy"}},[_vm._v("Duplicate")]):_vm._e()],1):_vm._e(),(
                          _vm.checkPerimission('EDIT') &&
                          _vm.checkPermission('UPDATE') &&
                          _vm.IframePermissions('EDIT', 'ViewEntityData') &&
                          _vm.checkButtonVisible('Edit') &&
                          !(
                            _vm.customizationButtons &&
                            _vm.customizationButtons.some(
                              (button) => button === 'Edit'
                            )
                          ) &&
                          _vm.checkRolePermission('editEntitiesData')
                        )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.editWorkUserData(_vm.entityDataObject[scope.row._id])}}},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"}},[_vm._v("Edit")])],1):_vm._e(),(
                          _vm.checkPerimission('VIEW') &&
                          _vm.customization != null &&
                          _vm.checkButtonVisible('View') &&
                          !(
                            _vm.customizationButtons &&
                            _vm.customizationButtons.some(
                              (button) => button === 'View'
                            )
                          ) &&
                          _vm.checkRolePermission('viewEntitiesData') &&
                          scope.row.status != 'DRAFT'
                        )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.viewWorkUserData(_vm.entityDataObject[scope.row._id])}}},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-view"}},[_vm._v("View")])],1):_vm._e(),(
                          _vm.hasAttachment &&
                          _vm.checkPerimission('Attachments') &&
                          _vm.checkButtonVisible('Attachments') &&
                          _vm.IframePermissions('ATTACHMENT', 'ViewEntityData') &&
                          !(
                            _vm.customizationButtons &&
                            _vm.customizationButtons.some(
                              (button) => button === 'Attachments'
                            )
                          ) &&
                          scope.row.status != 'DRAFT'
                        )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.openAttachments(_vm.entityDataObject[scope.row._id])}}},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-folder"}},[_vm._v("Attachment")])],1):_vm._e(),(
                          _vm.checkPerimission('SEND NOTIFICATION') &&
                          _vm.checkButtonVisible('Send Notification') &&
                          _vm.IframePermissions(
                            'SEND NOTIFICATION',
                            'ViewEntityData'
                          ) &&
                          !(
                            _vm.customizationButtons &&
                            _vm.customizationButtons.some(
                              (button) => button === 'Send Notification'
                            )
                          ) &&
                          scope.row.status != 'DRAFT'
                        )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.openSendNotification(
                            _vm.entityDataObject[scope.row._id]
                          )}}},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-s-promotion"}},[_vm._v("Send Notification")])],1):_vm._e(),(
                          _vm.checkPerimission('SEND NOTIFICATION') &&
                          _vm.checkButtonVisible('Send Notification') &&
                          _vm.IframePermissions(
                            'SEND NOTIFICATION',
                            'ViewEntityData'
                          ) &&
                          !(
                            _vm.customizationButtons &&
                            _vm.customizationButtons.some(
                              (button) => button === 'Send Notification'
                            )
                          ) &&
                          scope.row.status != 'DRAFT'
                        )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.openWASendNotification(
                            _vm.entityDataObject[scope.row._id]
                          )}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-chat-dot-round"}),_vm._v("Send WA Notification")])],1):_vm._e(),(
                          _vm.checkPerimission('SEND NOTIFICATION') &&
                          _vm.checkButtonVisible('Send Notification') &&
                          _vm.IframePermissions(
                            'SEND NOTIFICATION',
                            'ViewEntityData'
                          ) &&
                          !(
                            _vm.customizationButtons &&
                            _vm.customizationButtons.some(
                              (button) => button === 'Send Notification'
                            )
                          ) &&
                          scope.row.status != 'DRAFT'
                        )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.openScheduleEventModal(
                            _vm.entityDataObject[scope.row._id]
                          )}}},[_c('el-dropdown-item',[_c('i',{staticClass:"fa fa-calendar"}),_vm._v("Schedule Event")])],1):_vm._e(),(
                          _vm.checkPerimission('DELETE') &&
                          _vm.IframePermissions('DELETE', 'ViewEntityData') &&
                          _vm.checkButtonVisible('Delete') &&
                          !(
                            _vm.customizationButtons &&
                            _vm.customizationButtons.some(
                              (button) => button === 'Delete'
                            )
                          ) &&
                          _vm.checkRolePermission('deleteEntitiesData')
                        )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.onEntityDataDelete(_vm.entityDataObject[scope.row._id])}}},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"}},[_vm._v("Delete")])],1):_vm._e(),_vm._l((_vm.nonPrimaryActions),function(action,index){return _c('a',{key:index,attrs:{"underline":false},on:{"click":function($event){return _vm.onAction(action, _vm.entityDataObject[scope.row._id])}}},[_c('el-dropdown-item',{staticStyle:{"display":"flex","align-items":"center","gap":"2px"}},[(!action.icon)?_c('i',{class:_vm.fetchActionIcon(action),staticStyle:{"font-size":"16px"}}):_c('icons',{staticStyle:{"font-size":"14px"},attrs:{"iconName":action.icon}}),_c('span',[_vm._v(_vm._s(action.label))])],1)],1)})],2)],1)],1):_vm._e()]):_c('ul',[(_vm.checkRolePermission('viewEntitiesData'))?_c('button',{staticClass:"btn btn-light btn-sm m-lr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.viewWorkUserData(_vm.entityDataObject[scope.row._id])}}},[_c('span',{staticClass:"text-muted",attrs:{"title":"View"}},[_c('i',{staticClass:"fa fa-eye"})])]):_vm._e(),(
                    !_vm.isApplicationUserSide &&
                    !scope.row.isCreatedFromFormbuilder
                  )?_c('button',{staticClass:"btn btn-light btn-sm m-lr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmRestore([scope.row._id])}}},[_c('span',{staticClass:"text-muted",attrs:{"title":"Restore"}},[_c('i',{staticClass:"el-icon-refresh-left"})])]):_vm._e(),(
                    !_vm.isApplicationUserSide &&
                    !scope.row.isCreatedFromFormbuilder
                  )?_c('button',{staticClass:"btn btn-light btn-sm m-lr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmPermanentDelete([scope.row._id])}}},[_c('span',{staticClass:"text-muted",attrs:{"title":"Permanent Delete"}},[_c('i',{staticClass:"fa fa-trash-o"})])]):_vm._e()])]}}],null,false,2630630174)})],2):_vm._e()]],2)]:_vm._e(),(_vm.showAddRelationshipDataDialog)?_c('add-entity-reationship-data-widget',{attrs:{"relationshipDetails":_vm.selectedRelationshipDetails,"existedData":_vm.selectedRelationshipExistedIds,"autoPopup":true},on:{"entityDataClosed":function($event){_vm.showAddRelationshipDataDialog = false}}}):_vm._e(),(_vm.showGallery)?_c('GalleryModal',{attrs:{"images":_vm.currentImages,"isView":_vm.isView},on:{"close":function($event){_vm.showGallery = false}}}):_vm._e(),(_vm.quickActionModal && _vm.quickActionDummyField)?_c('ActionButtonExecute',{attrs:{"data":_vm.quickActionDummyField,"isView":false,"entityId":_vm.currentEntity._id,"entityDataId":_vm.quickActionDummyField.parent_entityDataId,"fromQuickAction":true,"entityData":_vm.rowEntityData,"selfTemplateId":_vm.quickActionDummyField.template_id,"allEntitiesData":_vm.allEntitiesData,"allEntityFields":_vm.allEntityFields,"form":_vm.quickActionDummyForm},on:{"closeModal":function($event){_vm.quickActionModal = false}}}):_vm._e(),(_vm.activeEntityView === 'TABLE' || _vm.activeEntityView === 'HTML_CONTENT')?[(!_vm.refreshPage)?_c('el-pagination',{class:'custom-pagination',attrs:{"data":this.data,"search_string":_vm.search_string,"current-page":_vm.currentPage,"page-sizes":_vm.paginationProps,"page-size":_vm.pageSize,"pager-count":5,"layout":"prev, pager, next, jumper,sizes, total","total":_vm.total,"large":""},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }